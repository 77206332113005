<template>
  <div
    v-if="selectedHero !== 0 && heroesInDraw === true"
    class="p-0 resultsGrid flex-grow-1"
    style="overflow: auto;"
  >
    <HeroCardToggle
      class="p-1 p-sm-2 d-flex align-self-center align-items-center h-100"
      style="margin-top: 5px;height: auto;"
      @selectedHero="selectedHero = $event"
    />
    <div
      class="d-flex justify-content-center"
      style="overflow: auto; height: 100%;width: 100%;position: relative"
    >
      <div
        style="position: absolute; top: 0; left:0; height: 100%; width: 100%;"
        class="d-flex align-items-center justify-content-center"
      >
        <i v-if="loading" class="fa fa-spinner fa-pulse primary-colour fa-3x" />
        <div v-if="!loading && previousResults.length === 0" class="primary-colour text-center">
          THIS HERO HASN'T BEEN PART OF ANY DRAWS YET
        </div>
      </div>
      <div
        v-show="previousResults.length > 0"
        class="d-flex flex-column flex-grow-0 mt-2"
        style="width:100%"
      >
        <FadeList>
          <div v-for="(draw, i) in previousResults" :key="i">
            <ResultsRow
              v-if="draw.DateDrawn > selectedHero.StartFrom"
              :draw="draw"
              :selected-hero="selectedHero"
            />
            <div
              v-else
              class="d-flex justify-content-center"
              style="overflow: auto; height: 100%;width: 100%;position: relative;"
            >
              <div
                class="d-flex align-items-center justify-content-center primary-colour text-center"
              >
                <span
                  v-if="i ===0"
                  class="mob-pos-top"
                  style="margin-top:192px;"
                >THIS HERO HASN'T BEEN PART OF ANY DRAWS YET</span>
              </div>
            </div>
          </div>
        </FadeList>
      </div>
    </div>
  </div>
  <div
    v-else
    style="width:100%;margin-top:15px;"
    class="text-center primary-colour"
  >
    ANY HEROES PURCHASED ARE NOT YET IN THE DRAW. DON'T HAVE A HERO YET? CLICK THE BUTTON BELOW TO GET STARTED.
    <br><br>
    <mdb-btn
      class="btn primary-btn btn-radius m-0"
      style="margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
      @click="$router.push({ path: '/play', query: { page: 1 } })"
    >
      CREATE MY HERO
    </mdb-btn>
  </div>
</template>

<script>
import baseService from '@/api-services/base.service'
import { mapGetters } from 'vuex'
import HeroCardToggle from './heroCardToggle.vue'
import ResultsRow from './resultsRow.vue'
import FadeList from '@/components/UI/fadeList.vue'

export default {
  name: 'ResultsChecker',
  components: {
    HeroCardToggle, ResultsRow, FadeList
  },
  data () {
    return {
      previousResults: [],
      loading: false,
      selectedHero: {},
      heroesInDraw: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    selectedHeroId () {
      return this.selectedHero.BasketOrdersId || 0
    }
  },
  watch: {
    selectedHeroId () {
      if (this.selectedHeroId != 0) {
        this.previousResults = []
        this.getAllHeroResults()
      }
    }
  },
  created () {
    if (this.$parent.currentPage === 'Results Checker') {
      this.gotAnyHeroes()
    }
  },
  methods: {
    async getAllHeroResults () {
      this.previousResults = []
      this.loading = true
      setTimeout(async () => {
        try {
          const res = await baseService.getAllHeroResults(this.selectedHeroId)
          setTimeout(() => {
            this.previousResults = res.data
            this.loading = false
          }, 800)
        } catch (err) {
          this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
          this.loading = false
        }
      }, 500)
    },
    gotAnyHeroes () {
      baseService.gotAnyHeroes(this.userData.access_token).then((res) => {
        this.heroesInDraw = res.data
      })
    }
  }
}
</script>

<style>
.heroCardToggleHeight {
  height: 50%;
}
@media (min-width: 992px) {
  .heroCardToggleHeight {
    height: 100%;
  }
}
.resultsGrid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
}
@media (max-width: 992px) {
  .resultsGrid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
@media (max-width: 576px) {
  .mob-pos-top {
    margin-top:10% !important;
  }
}
</style>
